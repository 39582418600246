import styled from "styled-components";
import colors from "../style/colors";

const H3 = styled.h3`
  font-size: 1.75em;
  text-align: ${props => (props.center ? "center" : "left")};
  color: ${props => (props.color ? colors[props.color] : colors.ultra_dark)};
  margin: ${props => (props.margin ? props.margin : "0.1em 0 0.6em")};
  font-weight: 700;
  line-height: ${props => (props.lineHeight ? props.lineHeight : 1.55)};
  white-space: pre-line;
  @media (max-width: 640px) {
    font-size: 1.6em;
  }
`;

export default H3;
