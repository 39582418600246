import getUrlParameter from "./getUrlParameter";
import axios from "axios";

function fetchUserAccount(checkoutId, callback) {
  axios
    .get(`https://accounts.boxysuite.com/checkout/${checkoutId}`)
    .then(response => {
      callback(response.data);
    })
    .catch(() => {
      console.error("Can't fetch user");
    });
}

const paywallUtils = {
  parseCouponDate: function(dateString) {
    // parses dates in this format: "2018-11-26 00:00:00"
    const datePart = dateString.match(/\d{4}-\d{2}-\d{2}/)[0];

    if (!datePart) {
      return null;
    }

    const utcDate = new Date(datePart);

    if (!utcDate) {
      return null;
    }

    // Paddle's coupons expiration date is in UTC
    // so we need to convert it to the local user timezone
    const userTimezoneOffset = new Date().getTimezoneOffset(); // minutes off wrt UTC
    const timezoneOffsetInMilliseconds = userTimezoneOffset * 60 * 1000;
    const localDate = new Date(utcDate.getTime() - timezoneOffsetInMilliseconds);

    return localDate;
  },
  checkExpiration: function(expiration) {
    const exp = new Date(expiration);
    const now = new Date();
    const expired = exp < now;
    if (expiration) {
      return expired;
    } else {
      return false;
    }
  },
  generatePassthrough: function() {
    const referralFromURL = getUrlParameter("referral", true);
    const sourceFromURL = getUrlParameter("ref", true);

    const referral = referralFromURL.length > 0 ? referralFromURL : null;
    const source = sourceFromURL.length > 0 ? sourceFromURL : null;

    const passthrough = {};

    if (referral) {
      passthrough.referral = referral;
    }

    if (source) {
      passthrough.source = source;
    }

    // if (referral != null) {
    //   trackGoal("Referral Link Visit", "0");
    // }

    return JSON.stringify(passthrough);
  },
  readCoupon: function() {
    let coupon = getUrlParameter("coupon", false);
    // if (coupon == "") {
    //   coupon = "black-friday";
    // }
    return coupon;
  },
  fetchAccountAndRedirect: function(checkoutId, timeOutCallback) {
    let timedOut = false; // Time out for user fetching

    setTimeout(() => {
      timedOut = true;
    }, 60000);

    const tryFetchAccount = setInterval(() => {
      fetchUserAccount(checkoutId, response => {
        if (response.status === "success") {
          clearInterval(tryFetchAccount);
          window.location.href = response.redirect_to;
        } else if (timedOut) {
          // If timed out, show the error page
          clearInterval(tryFetchAccount);
          timeOutCallback();
          console.error("Couldn't fetch the user");
        }
      });
    }, 5000);
  },
  fetchCoupon: function(value, callback) {
    axios
      .get(`https://accounts.boxysuite.com/coupons/${value}`)
      .then(response => {
        callback(response.data);
      })
      .catch(() => {
        console.error("Invalid or expired coupon.");
      });
  }
};

export default paywallUtils;
