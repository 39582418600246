import React from "react";
import colors from "../style/colors";

const Select = ({ placeholder, values, selected: providedSelected, setSelected, style }) => {
  const selected = providedSelected ? providedSelected : "";
  return (
    <select
      name="cars"
      id="cars"
      style={{
        WebkitAppearance: "none",
        cursor: "pointer",
        border: `1px solid ${selected ? colors.accent1 : colors.light}`,
        borderRadius: "10px",
        padding: "8px 6px",
        fontSize: "17px",
        fontWeight: "500",
        color: selected ? colors.ultra_dark : colors.dark,
        outline: "none",
        ...style
      }}
      value={selected}
      onChange={e => setSelected && setSelected(e.target.value)}
    >
      {placeholder && (
        <option disabled value="">
          {placeholder}
        </option>
      )}
      {values &&
        values.map((v, i) => (
          <option value={v} key={i}>
            {v}
          </option>
        ))}
    </select>
  );
};

export default Select;
