/* globals ga */

const track = {
  initiate: function() {
    if (typeof ga !== "undefined") {
      ga("send", {
        hitType: "event",
        eventCategory: "Paywall",
        eventAction: "Initiate Checkout",
        eventLabel: "Open Paywall"
      });
    }
  },
  purchase: function(product, value) {
    // product: Lifetime, Boxy Suite, Boxy Suite Monthly, Team Subscription
    // value: 49.99, 149.99 ...

    let convertedValue = parseFloat((value * 0.3).toFixed(2)); // Our conversion

    // Analytics
    if (typeof ga !== "undefined") {
      ga("send", {
        hitType: "event",
        eventCategory: "Paywall",
        eventAction: "Purchase",
        eventLabel: product,
        eventValue: convertedValue
      });
    }
  }
};

export default track;
