function getUrlParameter(name, cacheResult) {
  if (typeof window === "undefined") return null;
  name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
  const regex = new RegExp(`[\\?&]${name}=([^&#]*)`);
  const results = regex.exec(window.location.search);
  let result = results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
  result = result || "";

  if (cacheResult === true) {
    const key = `url_param_${name}`;
    if (result && result.length > 0) {
      localStorage.setItem(key, result);
      return result;
    }
    return localStorage.getItem(key) || "";
  }
  return result;
}

export default getUrlParameter;
