import React from "react";
import styled from "styled-components";

const PopupWrapperWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  padding: 2em;
  overflow: scroll;
  display: flex;
  transition: opacity 0.2s;
  opacity: ${props => (props.show ? "1" : "0")};
  pointer-events: ${props => (props.show ? "auto" : "none")};
  z-index: 3;
  will-change: opacity;
  > div:last-child {
    max-width: 54em;
    transition: transform 0.35s cubic-bezier(0.2, 0, 0.1, 1);
    transform: ${props => (props.show ? "scale(1)" : "scale(.96)")};
    will-change: transform;
    margin: auto;
  }
  @media (max-width: 640px) {
    ${props => props.nomobile && "display: none !important;"}
    overflow: scroll;
    display: block;
    padding: 0;
    > div:last-child {
      padding: 1.8em 0.6em 2em;
      min-height: 100%;
      width: 100%;
      border-radius: 0;
    }
  }
  ${props =>
    props.top &&
    `
    align-items: flex-start;
  `}
`;

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.1);
  z-index: -1;
`;

const PopupWrapper = props => {
  const { show, nomobile, top, onOutsideClick } = props;

  return (
    <PopupWrapperWrapper show={show} top={top} nomobile={nomobile}>
      <Backdrop onClick={onOutsideClick} />
      {props.children}
    </PopupWrapperWrapper>
  );
};

export default PopupWrapper;
