/* eslint-disable jsx-a11y/accessible-emoji */
/* globals Paddle */

import axios from "axios";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Confetti from "react-dom-confetti";
import { StageSpinner } from "react-spinners-kit";
import styled from "styled-components";
import Close from "../../assets/glyphs/close.svg";
import currencySymbol from "../utils/currencySymbol";
import parsePrice from "../utils/parsePrice";
import paywallUtils from "../utils/paywallUtils";
import track from "../utils/track";
import Image from "./image";
import Card from "./layout/Card";
import colors from "./style/colors";
import H3 from "./typography/H3";
import P2 from "./typography/P2";
import Button from "./ui/Button";
import Select from "./ui/Select";
import Switch from "./ui/Switch";

const Paywall = props => {
  const { setPaywallVisibility, coupon, setCoupon, couponCode, setCouponCode } = props;

  const paddleEmbedRef = useRef();
  const [checkoutLoaded, setCheckoutLoaded] = useState(true);
  const [offerRedeemed, setOfferRedeemed] = useState(false);

  const discount = coupon ? coupon.discount : 0;

  const [billing, setBilling] = useState(0);
  const [page, setPage] = useState("paywall"); // paywall, success, offer
  const [confettiActive, setConfettiActive] = useState(false);
  const [checkoutInfo, setCheckoutInfo] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [allPricingInfo, setAllPricingInfo] = useState(null);
  const [trialDays, setTrialDays] = useState(null);

  const getAllPricingInfo = async () => {
    // Fetch pricing info via our endpoiunt
    const response = await axios.get(
      `https://accounts.boxysuite.com/proxy/?url=https://checkout.paddle.com/api/2.0/prices?product_ids=${paddleProducts
        .map(product => product.id)
        .join(",")}`
    );
    if (response.data.response.products) {
      setAllPricingInfo(response.data.response.products);
    }
  };

  let currentProductInfo = null;

  useEffect(() => {
    // Pick the right product from the response,
    // then look for a trial length and
    // finally save it in a hook
    if (allPricingInfo && selectedProduct) {
      currentProductInfo = allPricingInfo.find(product => product.product_id === selectedProduct);
      if (currentProductInfo) {
        if (currentProductInfo.subscription) {
          if (currentProductInfo.subscription.trial_days) {
            setTrialDays(currentProductInfo.subscription.trial_days);
          } else {
            setTrialDays(null);
          }
        } else {
          setTrialDays(null);
        }
      }
    }
  }, [allPricingInfo, selectedProduct]);

  useEffect(() => {
    if (page === "success") {
      setConfettiActive(true);
    }
  }, [page]);

  const handleCheckoutInfo = useCallback(
    data => {
      setCheckoutLoaded(true);
      setSelectedProduct(data.product.id);
      setCheckoutInfo(parseCheckoutInfo(data));
    },
    [setCheckoutLoaded, setSelectedProduct, setCheckoutInfo]
  );

  useEffect(() => {
    Paddle.Setup({
      vendor: 28827,
      eventCallback: function(d) {
        // reference here: https://paddle.com/docs/paddlejs-callbacks/
        if (
          d.event === "Checkout.Loaded" ||
          d.event === "Checkout.Coupon.Applied" ||
          d.event === "Checkout.Coupon.Remove"
        ) {
          handleCheckoutInfo(d.eventData);
          setTimeout(() => {
            handleCheckoutInfo(d.eventData);
          }, 2000);
        }
        if (d.event === "Checkout.Coupon.Remove") {
          setCoupon({
            active: false,
            discount: null,
            text: "...",
            expiration: null
          });
        }
      }
    });
    getAllPricingInfo();
  }, []);

  useEffect(() => {
    const productID = paddleProducts[billing].id;
    setupCheckout(productID);
  }, [billing, couponCode]);

  function checkoutComplete(data) {
    localStorage.setItem("purchased", true);
    setPage("success");
    const checkoutCompleteInfo = parseCheckoutInfo(data);
    let product = checkoutCompleteInfo.type == "recurring" ? "Boxy Suite" : "Lifetime";
    track.purchase(product, parseFloat(checkoutCompleteInfo.value));
    paywallUtils.fetchAccountAndRedirect(data.checkout.id, () => {
      console.log("error");
    });
  }

  function setupCheckout(id) {
    if (typeof Paddle !== "undefined") {
      Paddle.Checkout.open({
        method: "inline",
        product: id,
        allowQuantity: "false",
        disableLogout: false,
        coupon: couponCode,
        frameTarget: "checkout-container",
        frameInitialHeight: 375,
        frameStyle: "width:100%; border: none; background: transparent;",
        successCallback: data => {
          checkoutComplete(data, checkoutInfo);
        },
        // closeCallback: "checkoutClosed"
        passthrough: paywallUtils.generatePassthrough()
      });
    }
  }

  function parseCheckoutInfo(data) {
    const checkoutData = data.checkout;

    // debugger;
    if (checkoutData.recurring_prices) {
      return {
        id: checkoutData.id,
        type: "recurring",
        value: checkoutData.recurring_prices.customer.total,
        currency: currencySymbol(checkoutData.recurring_prices.customer.currency),
        interval: checkoutData.recurring_prices.interval.type
      };
    } else {
      return {
        id: checkoutData.id,
        type: "one_time",
        value: checkoutData.prices.customer.total,
        currency: currencySymbol(checkoutData.prices.customer.currency),
        interval: null
      };
    }
  }

  const paywallTitle = (() => {
    if (checkoutInfo) {
      if (checkoutInfo.type == "recurring") {
        if (trialDays) {
          return (
            <>
              <H3 color="ultra_dark" center style={{ margin: "8px 0 0", fontSize: "1.1em", fontWeight: 600 }}>
                Try it for <Highlight>2 weeks</Highlight> completely free
              </H3>
              <P2 style={{ fontSize: "15px", marginTop: "0.5em", marginBottom: "0.6rem" }}>
                No charge until the end of trial.
              </P2>
            </>
          );
        } else {
          return (
            <H3 color="accent1" center style={{ margin: 0, fontSize: "1.4em", fontWeight: 600 }}>
              Get Started
            </H3>
          );
        }
      } else if (checkoutInfo.type == "one_time") {
        return (
          <H3 center style={{ margin: 0 }}>
            Get a Lifetime License
          </H3>
        );
      }
    }
  })();

  const strk = {
    textDecoration: "line-through",
    color: "hsl(00, 0%, 70%)",
    fontStyle: "normal"
  };

  const pricingText = () => {
    if (checkoutInfo) {
      let price = Number.parseFloat(checkoutInfo.value).toFixed(2);
      let curr = checkoutInfo.currency;
      let originalPrice = Number.parseFloat((price / (100 - discount)) * 100).toFixed(2);
      let noDiscount = !(coupon && coupon.active) || !checkoutLoaded;

      if (checkoutInfo.interval === "year") {
        if (noDiscount) {
          return (
            <H3 center style={{ fontSize: "2.2rem", margin: 0, fontWeight: 400 }}>
              {parsePrice(curr, price)}
              <span style={{ fontSize: "0.7em", color: "rgba(0,0,0,.6)" }}>/year</span>
            </H3>
          );
        } else {
          return (
            <H3 center style={{ fontSize: "1.8rem", margin: 0, fontWeight: 400 }}>
              <em style={strk}>{parsePrice(curr, originalPrice)}</em> {parsePrice(curr, price)}
              <span style={{ fontSize: "0.7em", color: "rgba(0,0,0,.6)" }}>/year</span>
            </H3>
          );
        }
      } else if (checkoutInfo.interval == "month") {
        if (noDiscount) {
          return (
            <H3 center style={{ margin: 0, fontWeight: 400 }}>
              {parsePrice(curr, price)}/month
            </H3>
          );
        } else {
          return (
            <H3 center style={{ margin: 0, fontWeight: 400 }}>
              <em style={strk}>{parsePrice(curr, originalPrice)}</em> {parsePrice(curr, price)}
              /month
            </H3>
          );
        }
      } else if (checkoutInfo.type == "one_time") {
        if (!discount || discount == 100) {
          return <P2 center>One time purchase of {parsePrice(curr, price)}</P2>;
        } else {
          return (
            <>
              <P2 center style={{ margin: 0 }}>
                One time purchase of <em style={strk}>{parsePrice(curr, originalPrice)}</em> {parsePrice(curr, price)}
              </P2>
              <P2 color="accent1" center style={{ margin: 0, fontSize: ".95em" }}>
                Get all major updates, forever
              </P2>
            </>
          );
        }
      }
    }
  };

  function handleCloseClick() {
    setPaywallVisibility(false);

    // const alreadyPurchased = localStorage.purchased;
    // if (page === "paywall" && !alreadyPurchased && !offerRedeemed && !coupon) {
    //   setPage("offer");
    // } else {
    //   setPaywallVisibility(false);
    // }
  }

  const [question1, setQuestion1] = useState(null);
  const [question2, setQuestion2] = useState(null);

  const offerValid = question1 && question2;

  return (
    <>
      <Card center width="34em" miHeight="38.6em">
        <StyledClose onClick={handleCloseClick} />
        <Page show={page === "paywall"}>
          {paddleProductsNames.length > 1 && (
            <Switch
              labels={paddleProductsNames}
              selected={billing}
              labelClick={index => {
                setCheckoutLoaded(false);
                setBilling(index);
              }}
            />
          )}
          {checkoutLoaded && pricingText()}
          {checkoutLoaded && paywallTitle}
          <div ref={paddleEmbedRef} className="checkout-container" />
          <br />
          {/* <Button
            small
            onClick={() => {
              setPage("success");
            }}
          >
            Complete Checkout
          </Button>
          <br /> */}
        </Page>
        <Page show={page === "success"}>
          <Confetti active={confettiActive} config={confettiConfig} />
          <Image filename="SuitePurchase" alt="Purchasing Boxy Suite" style={{ width: "8em", margin: "0 auto 1em" }} />
          <StageSpinner size={40} color={colors.grey} loading={true} />
          <H3 center>Please wait...</H3>
          <P2 color="ultra_dark" center>
            You're now a Boxy Suite user! 🚀
          </P2>
          <P2 color="ultra_dark" center>
            We're thrilled to have you on board. This really means a lot to us. Thank
            you 🙏
          </P2>
          <P2 color="ultra_dark" center>
            <strong>Don't close this page, we are processing your order.</strong>
          </P2>
          <P2 style={{ fontSize: ".8em" }} center>
            {/* Debug ID: {(() => checkoutInfo.id || "")()} */}
          </P2>
        </Page>
        <Page show={page === "offer"}>
          <H3 style={{ marginBottom: "0.1em" }}>Not sure yet?</H3>
          <P2 style={{ marginBottom: "1.2em" }} color="ultra_dark">
            Get a <Highlight style={{ fontWeight: 600 }}>50% discount</Highlight> by helping us know our audience.
          </P2>
          <Select
            placeholder="How long have you been a Mac user?"
            values={["Less than 1 year", "2-5 years", "More than 5 years", "I'm not a Mac user"]}
            selected={question1}
            setSelected={setQuestion1}
            style={{ width: "100%", marginBottom: "0.6rem" }}
          />
          <Select
            placeholder="How many email accounts do you regularly check?"
            values={["1 account", "2-5 accounts", "More than 5 accounts"]}
            selected={question2}
            setSelected={setQuestion2}
            style={{ width: "100%", marginBottom: "0.6rem" }}
          />
          <Button
            style={{ marginTop: "1rem" }}
            small
            disabled={!offerValid}
            onClick={() => {
              if (offerValid) {
                setCheckoutLoaded(false);
                setCouponCode("2d259w4");
                setPage("paywall");
                setOfferRedeemed(true);
              }
            }}
          >
            Claim 50% Discount
          </Button>
        </Page>
      </Card>
    </>
  );
};

const Page = styled.div`
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  .checkout-container {
    width: 100%;
  }
  ${props =>
    props.show
      ? `
  
  `
      : `
    pointer-events: none;
    user-select: none;
    opacity: 0;
    height: 0;
    max-height: 0;
    overflow: hidden;
    z-index: -1;
  `}
`;

const confettiConfig = {
  angle: "95",
  spread: "181",
  startVelocity: "99",
  elementCount: 50,
  dragFriction: "0.25",
  duration: "8180",
  delay: "3",
  width: "10px",
  height: "10px",
  colors: [colors.accent1, colors.accent2, colors.accent3, colors.dark]
};

export const StyledClose = styled(Close)`
  position: absolute;
  top: 1em;
  right: 1em;
  cursor: pointer;
  width: 30px;
  path {
    fill: ${props => (props.color ? colors[props.color] : colors.grey)};
  }
  :hover {
    path {
      fill: ${props => (props.color ? colors[props.color] : colors.dark)};
    }
  }
`;

const Highlight = styled.span`
  display: inline-block;
  position: relative;
  z-index: 2;
  color: #443d1a;
  ::before {
    content: "";
    position: absolute;
    top: 0px;
    left: -3px;
    right: -3px;
    bottom: 0px;
    background: hsla(50, 100%, 85%);
    z-index: -1;
    transform: skewX(-8deg) rotate(-0.5deg);
    border-radius: 3px;
  }
`;

const paddleProducts = [
  // {
  //   id: "589200",
  //   slug: "monthly",
  //   name: "Monthly Billing"
  // },
  {
    id: "657417",
    slug: "yearly",
    name: "Yearly Billing"
  }
];

const paddleProductsNames = paddleProducts.map(prod => prod.name);

export default Paywall;
