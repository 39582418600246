import React from "react";
import styled from "styled-components";
import colors from "../style/colors";

const SwitchWrapper = styled.div`
  display: inline-grid;
  grid-template-columns: auto auto;
  grid-gap: 0.5em;
  background: ${colors.light};
  border-radius: 2em;
  margin-bottom: 1.2em;
  align-self: center;

  span {
    background: ${colors.dark};
    color: white;
    border-radius: 2em;
    padding: 0.4em 1.2em;
    font-size: 0.9em;
    transition: 0.1s all cubic-bezier(0.3, 0.3, 0.2, 0.9);
    text-align: center;
    display: flex;
    cursor: pointer;
    place-items: center;
    &:not(.selected) {
      color: ${colors.ultra_dark};
      background: none;
      box-shadow: none;
      :first-child {
        padding-right: 0.2em;
      }
      :last-child {
        padding-left: 0.2em;
      }
    }
  }
`;

const Switch = props => {
  const { labels, selected, labelClick } = props;

  return (
    <SwitchWrapper>
      {labels.map((label, index) => (
        <span
          key={label}
          className={index == selected ? "selected" : ""}
          onClick={() => {
            labelClick(index);
          }}
        >
          {label}
        </span>
      ))}
    </SwitchWrapper>
  );
};

export default Switch;
